import React from "react"
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import SEO from "../components/seo"
import Article from "../templates/article.js"
import Page from "../templates/page.js"

const linkResolver = doc => {
  return `/${doc.type}s/${doc.uid}`
}

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
